import React, { Component } from 'react';
import { Col, ButtonToolbar, Button } from 'react-bootstrap';
import LoginPage from '../LoginPage/loginpage';

class Homepage extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    return (
      <div>
        <LoginPage history={this.props.history}></LoginPage>
        {/* <Col lgOffset={5} lg={2} mdOffset={4} md={4} smOffset={3} sm={6} xsOffset={2} xs={8} className="align-middle pd-20">
          <a href="/sign-up" className="register-tag">Sign up</a>
        </Col> */}
      </div>
    );
  }
}

export default Homepage;