import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';

class LogoutNavComponent extends Component {
  constructor(props, context) {
    super(props, context);
		this.state = {
			isLoggedIn : localStorage.getItem("user") !== null
		}
  }

  handleLogout = (e) => {
    localStorage.removeItem('user');
    this.props.history.push('/');
  }

  render() {
		const { isLoggedIn } = this.state;
    return (
			<Navbar.Collapse className="logout-wrapper">
				{isLoggedIn && 
					<Navbar.Text className="logout" onClick={this.handleLogout}>
					</Navbar.Text>
				}
			</Navbar.Collapse>
    );
  }
}

export default LogoutNavComponent;
