import React, { Component } from 'react';
import { Col, Table, ButtonToolbar, DropdownButton, MenuItem, Button, Modal, FormGroup, ControlLabel, FormControl, Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import ConfirmDeleteModal from '../_components/ConfirmDeleteModal';
import * as Constants from '../_constants/api';

class CitiesPage extends Component {

	constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
			showAddModal: false,
			name: '',
			Countries: [],
			deleteThis: '',
			showConfirmModal: false
    };
  }

  handleClose = () => {
    this.setState({ showAddModal: false });
  }

  handleShow = () => {
    this.setState({ showAddModal: true, name: '' });
  }

	handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleDelete = (e) => {
		this.setState({
			showConfirmModal: true,
			deleteThis: e.target.getAttribute('deleteid')
		});
  }

	handleConfirmClose = () => {
    this.setState({ showConfirmModal: false });
  }

	deleteData = () => {
		const headers = {
			Authorization: localStorage.getItem('user')
		};
		const data = {
			cityId: this.state.deleteThis
		};

		this.setState({ loading: true });
		axios.delete(Constants.API_PATH + 'cities', {headers, data})
		.then(result => {
			if(result.data.status === 1) {
    		this.setState({ showConfirmModal: false, deleteThis: '' });
				this.loadData();
			} else if(result.data.status === -1) {
				console.log(result.data.error);
			}
		})
		.catch(error => {
			console.log(error);
		});
	}

	handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, Country } = this.state;

    // stop here if form is invalid
    if (!(name)) {
        return;
    }

    this.setState({ loading: true });
		axios.post(Constants.API_PATH + 'cities', {
			name: this.state.name,
			country: this.state.Country
		}, {headers: {
			Authorization: localStorage.getItem('user')
		}})
		.then(result => {
			if(result.data.status === 1) {
				this.handleClose();
				this.loadData();
			} else if(result.data.status === -1) {
				console.log(result.data.error);
			}
		})
		.catch(error => {
			console.log(error);
		});
	}

	componentDidMount = () => {
		this.loadData();
		this.loadCountries();
	}

	async loadCountries () {
		try {
			const result = await axios.get(Constants.API_PATH + 'countries', {
				headers: {
					Authorization: localStorage.getItem('user')
				}
			});
			if(result.data.status === 1) {
				this.setState({
					Countries : result.data.response,
					isLoading: false
				});
			}
		} catch(error) {

		}
	}

	async loadData() {
		this.setState({ isLoading: true });
    try {
      const result = await axios.get(Constants.API_PATH + 'cities', {
				headers: {
					Authorization: localStorage.getItem('user')
				}
			});
			if(result.data.status === 1) {
				this.setState({
					data : result.data.response,
					isLoading: false
				});
			} else {
				this.setState({
					error : result.data.error,
					isLoading: false
				});
			}
    } catch (error) {
      this.setState({
        error,
        isLoading: false
      });
    }
  }
	
	
  render() {
		const { name, Country } = this.state;
    return (
      <div>
				<Col lgOffset={2} lg={8} mdOffset={2} md={8} smOffset={1} sm={10} xsOffset={1} xs={10} className="align-middle pd-20 white-box">
					<ButtonToolbar className="mrgb-10">
						<Button onClick={this.handleShow} className="primary-button">Add <Glyphicon glyph="plus" /> </Button>
					</ButtonToolbar>
					<Table striped condensed hover responsive bordered>
						<thead>
							<tr>
								<th>Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(thisData =>
								<tr key={thisData._id}>
									<td>{thisData.name}</td>
									<td>
										<DropdownButton title="Action" id="bg-vertical-dropdown-1" className="primary-button">
											<MenuItem eventKey="1" deleteid={thisData._id} onClick={this.handleDelete}> <Glyphicon glyph="remove" /> Remove</MenuItem>
										</DropdownButton>
                  					</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
				<Modal show={this.state.showAddModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add City</Modal.Title>
          </Modal.Header>
          <Modal.Body>
						<form name="form" onSubmit={this.handleSubmit}>
							<FormGroup controlId="formEmail" >
								<ControlLabel>Name</ControlLabel>
								<FormControl type="text" value={name}  name="name" placeholder="City name" onChange={this.handleChange} />
								<FormControl.Feedback />
							</FormGroup>
							<FormGroup controlId="formEmail" >
								<ControlLabel>Country</ControlLabel>
								<FormControl componentClass="select" name="Country" placeholder="Country" onChange={this.handleChange} value={Country}>
									<option value=""> Select </option>
									{this.state.Countries.map(country =>
										<option key={country._id} value={country._id} >{country.name}</option>
									)}
								</FormControl>
								<FormControl.Feedback />
							</FormGroup>
							<ButtonToolbar>
								<Button bsStyle="info" type="submit">Save</Button>
							</ButtonToolbar>
					</form>
					</Modal.Body>
				</Modal>
				<ConfirmDeleteModal show={this.state.showConfirmModal} action={this.deleteData} close={this.handleConfirmClose}></ConfirmDeleteModal>

				{this.state.isLoading && 
					<div className="modal-backdrop in">
						<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
					</div>
				}
      </div>
    );
  }
}

export default CitiesPage;