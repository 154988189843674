import React, { Component } from 'react';
import { Col, Table, ButtonToolbar, DropdownButton, MenuItem, Button, Modal, FormGroup, ControlLabel, FormControl, Glyphicon, Pager } from 'react-bootstrap';
import axios from 'axios';
import ConfirmDeleteModal from '../_components/ConfirmDeleteModal';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import * as Constants from '../_constants/api';

class DashboardPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			dataPrepoluated: false,
			data: [],
			Hospitaldata: [],
			geneMutationData: [],
			DSSGdata: [],
			showAddModal: false,
			NCTNo: '',
			DSSG: '',
			StudyName: '',
			CancerTrialsNumber: '',
			OverallStatus: '',
			Eligibilities: '',
			BriefTitle: '',
			Phase: '',
			deleteThis: '',
			showConfirmModal: false,
			isFirstPage: true,
			isLastPage: false,
			pageNum: 1,
			isEditing: false,
			Hospitals: [
				{
					'_id': '',
					'PIStatus': '',
					'Investigator': ''
				}
			],
			Neoadjuvant: false,
			Adjuvant: false,
			MetastaticLocallyAdvanced: false,
			SearchKey: '',
			sortEnabled: false,
			createdAsc: null,
			updatedAsc: null, 
		};
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handelDelete = this.handelDelete.bind(this);
		this.handleConfirmClose = this.handleConfirmClose.bind(this);
		this.deleteData = this.deleteData.bind(this);
		this.loadData = this.loadData.bind(this);
		this.loadTableData = this.loadTableData.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.prevPage = this.prevPage.bind(this);
	}

	handleClose() {
		this.setState({ showAddModal: false });
	}

	handleShow() {
		this.setState({
			isEditing: false,
			showAddModal: true,
			NCTNo: '',
			DSSG: '',
			StudyName: '',
			CancerTrialsNumber: '',
			OverallStatus: '',
			Eligibilities: '',
			BriefTitle: '',
			Phase: '',
			GeneMutations: [],
			DSSGList: [],
			dataPrepoluated: false,
			Hospitals: [
				{
					'_id': '',
					'PIStatus': '',
					'Investigator': ''
				}
			],
			_id: '',
			Neoadjuvant: false,
			Adjuvant: false,
			MetastaticLocallyAdvanced: false,
			geneMutationData: []
		});
	}

	handleChange = (e) => {
		let { name, value } = e.target;
		if (name === 'DSSG') {
			this.getGeneMutationData(this.state.DSSGdata[e.target.selectedIndex - 1].name);
		}
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		let Hospitals = [...this.state.Hospitals];
		if (e.target.dataset.id) {
			Hospitals[e.target.dataset.id][e.target.dataset.name] = e.target.value
			this.setState({ Hospitals })
		} else {
			this.setState({ [name]: value });
		}
	}

	handleGeneMutationChange = (value) => {
		this.setState({ 'GeneMutations': value });
	}

	handleDSSGChange = (value) => {
		this.setState({ 'DSSGList': value });
		let dssgList = value.map(({_id,name}) => {return name});
		this.getGeneMutationData(dssgList)
	}

	async getGeneMutationData(value) {
		const data = {
			dssgList: value
		};
		var json = await axios.post(Constants.API_PATH + 'dssg/genemutations', data);
		this.setState({
			geneMutationData: json.data.response
		});
		return json;
	}

	async handleEdit(data) {
		const dssgData = data.DSSGList.map(({_id,name}) => {return name});
		var json = await this.getGeneMutationData(dssgData);
		var stateData = {
			NCTNo: data.NCTNo,
			DSSG: data.DSSG,
			StudyName: data.StudyName,
			CancerTrialsNumber: data.CancerTrialsNumber,
			OverallStatus: data.OverallStatus,
			Eligibilities: data.Eligibilities,
			BriefTitle: data.BriefTitle,
			Phase: data.Phase,
			Hospitals: data.Hospitals,
			_id: data._id,
			isEditing: true,
			Neoadjuvant: data.Neoadjuvant,
			Adjuvant: data.Adjuvant,
			MetastaticLocallyAdvanced: data.MetastaticLocallyAdvanced,
			GeneMutations: data.GeneMutations,
			DSSGList: data.DSSGList
		};
		stateData.showAddModal = true;
		this.setState(stateData);
	}

	handelDelete(e) {
		this.setState({
			showConfirmModal: true,
			deleteThis: e.target.getAttribute('deleteid')
		});
	}

	handleConfirmClose() {
		this.setState({ showConfirmModal: false });
	}

	async prePopulateData() {
		const data = {
			NCTNo: this.state.NCTNo
		};
		var json = await axios.post(Constants.API_PATH + 'getClinicalTrialsData', data);
		this.setState({
			dataPrepoluated: true
		});
		if (json.data.status === 1) {
			this.setState({
				BriefTitle: json.data.brief_title,
				Eligibilities: json.data.eligibilities
			})
		}
	}

	deleteData() {
		const headers = {
			Authorization: localStorage.getItem('user')
		};
		const data = {
			trialNo: this.state.deleteThis
		};

		this.setState({ loading: true });
		axios.delete(Constants.API_PATH + 'trials', { headers, data })
			.then(result => {
				if(result.data.status === 1) {
					this.setState({ showConfirmModal: false, deleteThis: '' });
					this.loadTableData();
				} else if(result.data.status === -1) {
				}
			})
			.catch(error => {
			});
	}

	handleSubmit(e) {
		e.preventDefault();

		this.setState({ submitted: true });
		const { NCTNo, DSSG, StudyName, CancerTrialsNumber, Investigator, PIStatus, Hospitals } = this.state;

		const data = {
			NCTNo: this.state.NCTNo,
			StudyName: this.state.StudyName,
			CancerTrialsNumber: this.state.CancerTrialsNumber,
			OverallStatus: this.state.OverallStatus,
			Eligibilities: this.state.Eligibilities,
			BriefTitle: this.state.BriefTitle,
			Phase: this.state.Phase,
			Hospitals: this.state.Hospitals,
			Neoadjuvant: this.state.Neoadjuvant,
			Adjuvant: this.state.Adjuvant,
			MetastaticLocallyAdvanced: this.state.MetastaticLocallyAdvanced,
			GeneMutations: this.state.GeneMutations.map(({_id, ...created_at}) => {return {'_id' : _id}}),
			DSSGList: this.state.DSSGList.map(({_id, ...created_at}) => {return {'_id' : _id}})
		}
		if (this.state._id && this.state._id != '') {
			data.trialNo = this.state._id
		}

		// stop here if form is invalid
		if (!(NCTNo && StudyName && CancerTrialsNumber)) {
			return;
		}

		this.setState({ loading: true });
		axios.post(Constants.API_PATH + 'trials', data, {
			headers: {
				Authorization: localStorage.getItem('user')
			}
		})
		.then(result => {
			if(result.data.status === 1) {
				this.setState({
					_id: ''
				});
				this.handleClose();
				this.loadTableData();
			} else if(result.data.status === -1) {
			}
		})
		.catch(error => {
		});
	}

	componentDidMount() {
		this.loadData();
	}

	nextPage() {
		const newPageNum = this.state.pageNum + 1;
		this.setState({ pageNum: newPageNum });
		this.loadTableData();
	}

	prevPage() {
		const newPageNum = this.state.pageNum - 1;
		this.setState({ pageNum: newPageNum });
		this.loadTableData();
	}

	async loadTableData(api: null) {
		const listAPI = api? api : Constants.API_PATH + 'trials?page=' + this.state.pageNum + '&search=' + this.state.SearchKey;
		this.setState({ isLoading: true });
		try {
			const result = await axios.get(listAPI, {
				headers: {
					Authorization: localStorage.getItem('user')
				}
			});
			if(result.data.status === 1) {
				const setData = {
					data: result.data.response.data,
					isLoading: false
				}
				if (result.data.response.current_page !== 1) {
					setData.isFirstPage = false;
				}
				if (result.data.response.last_page == this.state.pageNum) {
					setData.isLastPage = true;
				}
				this.setState(setData);
			} else {
				this.setState({
					error: result.data.error,
					isLoading: false
				});
			}
		} catch (error) {
			this.setState({
				error,
				isLoading: false
			});
		}
	}

	async loadData() {
		this.loadTableData();
		try {
			const result = await axios.get(Constants.API_PATH + 'hospitals', {
				headers: {
					Authorization: localStorage.getItem('user')
				}
			});
			if(result.data.status === 1) {
				this.setState({
					Hospitaldata: result.data.response,
					isLoading: false
				});
			} else {
				this.setState({
					error: result.data.error,
					isLoading: false
				});
			}
		} catch (error) {
			this.setState({
				error,
				isLoading: false
			});
		}
		try {
			const result = await axios.get(Constants.API_PATH + 'dssg', {
				headers: {
					Authorization: localStorage.getItem('user')
				}
			});
			if(result.data.status === 1) {
				this.setState({
					DSSGdata: result.data.response,
					isLoading: false
				});
			} else {
				this.setState({
					error: result.data.error,
					isLoading: false
				});
			}
		} catch (error) {
			this.setState({
				error,
				isLoading: false
			});
		}
	}

	addHospital = (e) => {
		this.setState((prevState) => ({
			Hospitals: [...prevState.Hospitals, {
				'_id': '',
				'PIStatus': '',
				'Investigator': ''
			}],
		}));
	}

	removeHospital = (idx) => {
		let hospitals = this.state.Hospitals;
		hospitals.splice(idx, 1);
		this.setState((prevState) => ({
			Hospitals: hospitals
		}));
	}

	handleSearch = (e) => {
		e.preventDefault();
		this.setState({ pageNum: 1, sortEnabled: false, createdAsc: null, updatedAsc: null });
		this.loadTableData();
	}

	clearSerch = (e) => {
		e.preventDefault();
		this.setState({ SearchKey: '' });
		setTimeout(function() {
				this.loadTableData();;
			}.bind(this), 500
		);
	}

	sortBy = (sortField) => {
		let listAPI = Constants.API_PATH + 'trials?page=' + this.state.pageNum + '&search=' + this.state.SearchKey + '&sortBy=' + sortField + '&sortType=';
		const stateData = { sortEnabled: true };
		switch (sortField) {
			case 'created_at':
				stateData.createdAsc = this.state.createdAsc === null ? true : !this.state.createdAsc;
				stateData.updatedAsc = null;
				listAPI += this.state.createdAsc ? 'asc' : 'desc';
				break;
			case 'updated_at':
				stateData.updatedAsc = this.state.updatedAsc === null ? true : !this.state.updatedAsc;
				stateData.createdAsc = null;
				listAPI += this.state.updatedAsc ? 'asc' : 'desc';
				break;
			default:
				break;
		};
		this.setState(stateData);
		this.loadTableData(listAPI);
	}

	render() {
		const { NCTNo, DSSG, StudyName, CancerTrialsNumber,
			OverallStatus, Eligibilities, BriefTitle, Phase, GeneMutations, DSSGList,
			Hospitals, Adjuvant, Neoadjuvant, MetastaticLocallyAdvanced, SearchKey } = this.state;
		return (
			<div>
				<Col lgOffset={1} lg={10} mdOffset={1} md={10} smOffset={1} sm={10} xsOffset={1} xs={10} className="align-middle pd-20 white-box">
					<form name="search-form" onSubmit={this.handleSearch} className="search-form">
						<FormGroup controlId="tableSearch" >
							<ControlLabel className="remove-search" onClick={this.clearSerch}>
								<Glyphicon glyph="remove" />
							</ControlLabel>
							<FormControl type="text" value={SearchKey} name="SearchKey" placeholder="Search" onChange={this.handleChange} />
							<FormControl.Feedback />
						</FormGroup>
						<ButtonToolbar className="clear-both">
							<Button bsStyle="info" type="submit" className="pull-right">
								<Glyphicon glyph="search"/>
							</Button>
						</ButtonToolbar>
					</form>
					<ButtonToolbar className="mrgb-10">
						<Button onClick={this.handleShow} className="primary-button">Add <Glyphicon glyph="plus" /> </Button>
					</ButtonToolbar>
					<Table striped condensed hover responsive bordered>
						<thead>
							<tr>
								<th>NCT No.</th>
								<th>Study Name</th>
								<th>Cancer Trials Number</th>
								<th>Overall Status</th>
								{/* <th>Eligibilities</th>
								<th>Brief Title</th> */}
								<th>DSSG Names</th>
								<th>Phase</th>
								<th className="clickable-header" onClick={() => this.sortBy('created_at')}>
									Created
									{this.state.sortEnabled && <span >
										 {this.state.createdAsc && <Glyphicon glyph="chevron-down" />}
										 {!this.state.createdAsc && this.state.createdAsc !== null && <Glyphicon glyph="chevron-up" />}
									</span>}
								</th>
								<th className="clickable-header" onClick={() => this.sortBy('updated_at')}>
									Updated
									{this.state.sortEnabled && <span >
										 {this.state.updatedAsc && <Glyphicon glyph="chevron-down" />}
										 {!this.state.updatedAsc && this.state.updatedAsc !== null && <Glyphicon glyph="chevron-up" />}
									</span>}
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(thisData =>
								<tr key={thisData._id}>
									<td>{thisData.NCTNo}</td>
									<td>{thisData.StudyName}</td>
									<td>{thisData.CancerTrialsNumber}</td>
									<td>{thisData.OverallStatus}</td>
									{/* <td>{thisData.Eligibilities}</td>
									<td>{thisData.BriefTitle}</td> */}
									<td>{thisData.DSSGNames}</td>
									<td>{thisData.Phase}</td>
									<td>{thisData.created_at}</td>
									<td>{thisData.updated_at}</td>
									<td>
										<DropdownButton title="Action" id="bg-vertical-dropdown-1" className="primary-button">
											<MenuItem eventKey="1" onClick={() => this.handleEdit(thisData)}><Glyphicon glyph="edit" /> Edit</MenuItem>
											<MenuItem eventKey="2" deleteid={thisData._id} onClick={this.handelDelete}><Glyphicon deleteid={thisData._id} glyph="remove" />Remove</MenuItem>
										</DropdownButton>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					<Pager>
						<Pager.Item previous onClick={this.prevPage} disabled={this.state.isFirstPage}>
							&larr; Previous Page
						</Pager.Item>
						<Pager.Item next onClick={this.nextPage} disabled={this.state.isLastPage}>
							Next Page &rarr;
						</Pager.Item>
					</Pager>
				</Col>
				<Modal show={this.state.showAddModal} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Add Clinical Trial</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form name="form" onSubmit={this.handleSubmit}>
							<FormGroup controlId="formEmail" className="white-box pd-20 clear-both mrgb-10">
								<ControlLabel>NCTNo</ControlLabel>
								<FormControl type="text" value={NCTNo} disabled={this.state.isEditing ? true : null} name="NCTNo" placeholder="NCTNo" onChange={this.handleChange} />
								<FormControl.Feedback />
							</FormGroup>
							{!this.state.isEditing &&
								<Button onClick={() => { this.prePopulateData() }} className='fr mrgb-10'>Populate</Button>}
							<div className="clear-both"></div>
							{(this.state.dataPrepoluated || this.state.isEditing) &&
								<div className="white-box pd-20 clear-both mrgb-10">
									<FormGroup controlId="formEmail" >
										<ControlLabel>DSSG</ControlLabel>
										<Picky
											name="DSSGList"
											value={DSSGList}
											options={this.state.DSSGdata}
											onChange={this.handleDSSGChange}
											open={false}
											valueKey="_id"
											labelKey="name"
											multiple={true}
											includeSelectAll={false}
											includeFilter={false}
											dropdownHeight={600}
											/>
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup controlId="formEmail" >
										<ControlLabel>Study Name</ControlLabel>
										<FormControl type="text" value={StudyName} name="StudyName" placeholder="Study Name" onChange={this.handleChange} />
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup controlId="formEmail" >
										<ControlLabel>Cancer Trials Number</ControlLabel>
										<FormControl type="text" value={CancerTrialsNumber} name="CancerTrialsNumber" placeholder="Cancer Trials Number" onChange={this.handleChange} />
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup controlId="formEmail" >
										<ControlLabel>Overall Status</ControlLabel>
										<FormControl componentClass="select" name="OverallStatus" placeholder="OverallStatus" onChange={this.handleChange} value={OverallStatus}>
											<option value=""> Select </option>
											<option value="Open" >Open</option>
											<option value="Closed">Closed</option>
										</FormControl>
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup controlId="formEmail" >
										<ControlLabel>Eligibilities</ControlLabel>
										<FormControl type="text" componentClass="textarea" value={Eligibilities} name="Eligibilities" placeholder="Eligibilities" onChange={this.handleChange} />
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup controlId="formEmail" >
										<ControlLabel>Brief Title</ControlLabel>
										<FormControl type="text" componentClass="textarea" value={BriefTitle} name="BriefTitle" placeholder="Brief Title" onChange={this.handleChange} />
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup controlId="formEmail" >
										<ControlLabel>Phase</ControlLabel>
										{/* <FormControl type="text" value={Phase}  name="Phase" placeholder="Phase" onChange={this.handleChange} /> */}
										<FormControl componentClass="select" name="Phase" placeholder="Phase" onChange={this.handleChange} value={Phase}>
											<option value=""> Select </option>
											<option value="Phase 1" >Phase 1</option>
											<option value="Phase 2">Phase 2</option>
											<option value="Phase 3" >Phase 3</option>
											<option value="Translational">Translational</option>
										</FormControl>
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup controlId="formEmail" >
										<ControlLabel>Gene Mutation</ControlLabel>
										<Picky
											name="GeneMutations"
											value={GeneMutations}
											options={this.state.geneMutationData}
											onChange={this.handleGeneMutationChange}
											open={false}
											valueKey="_id"
											labelKey="name"
											multiple={true}
											includeSelectAll={false}
											includeFilter={false}
											dropdownHeight={600}
											/>
										<FormControl.Feedback />
									</FormGroup>
									<FormGroup className="switch-input">
										<ControlLabel className="pdr-10"> Adjuvant </ControlLabel>
										<label className="switch">
											<FormControl type="checkbox" name="Adjuvant" value={Adjuvant} checked={Adjuvant} onChange={this.handleChange} />
											<FormControl.Feedback />
											<span className="switch-slider"></span>
										</label>
									</FormGroup>
									<FormGroup className="switch-input">
										<ControlLabel className="pdr-10"> Neoadjuvant </ControlLabel>
										<label className="switch">
											<FormControl type="checkbox" name="Neoadjuvant" value={Neoadjuvant} checked={Neoadjuvant} onChange={this.handleChange} />
											<FormControl.Feedback />
											<span className="switch-slider"></span>
										</label>
									</FormGroup>
									<FormGroup className="switch-input">
										<ControlLabel className="pdr-10"> Metastatic </ControlLabel>
										<label className="switch">
											<FormControl type="checkbox" name="MetastaticLocallyAdvanced" value={MetastaticLocallyAdvanced} checked={MetastaticLocallyAdvanced} onChange={this.handleChange} />
											<FormControl.Feedback />
											<span className="switch-slider"></span>
										</label>
									</FormGroup>
								</div>
							}
							{(this.state.dataPrepoluated || this.state.isEditing) &&
								Hospitals.map((val, idx) => {
									return (
										<div className="white-box pd-20 clear-both mrgb-10" key={idx}>
										<Button className="remove-investigator" onClick={() => this.removeHospital(idx)}> x </Button>
											<FormGroup controlId="formEmail" >
												<ControlLabel>Investigator</ControlLabel>
												<FormControl type="text" data-id={idx} value={val.Investigator} data-name="Investigator" placeholder="Investigator" onChange={this.handleChange} />
												<FormControl.Feedback />
											</FormGroup>
											<FormGroup controlId="formEmail" >
												<ControlLabel>PI Status</ControlLabel>
												<FormControl componentClass="select" data-id={idx} data-name="PIStatus" placeholder="PI Status" onChange={this.handleChange} value={val.PIStatus}>
													<option value=""> Select </option>
													<option value="National PI" >National PI</option>
													<option value="Local PI">Local PI</option>
												</FormControl>
												<FormControl.Feedback />
											</FormGroup>
											<FormGroup controlId="formEmail" >
												<ControlLabel>Hospital</ControlLabel>
												<FormControl componentClass="select" data-id={idx} data-name="_id" placeholder="Hospital" onChange={this.handleChange} value={val._id}>
													<option value=""> Select </option>
													{this.state.Hospitaldata.map(thisHospital =>
														<option key={thisHospital._id} value={thisHospital._id} >{thisHospital.name}</option>
													)}
												</FormControl>
												<FormControl.Feedback />
											</FormGroup>
										</div>
									)
								})
							}
							{
								(this.state.dataPrepoluated || this.state.isEditing) &&
								<div>
									<Button onClick={this.addHospital} className='fr mrgb-10'>Add More Investigators <Glyphicon glyph="plus" /> </Button>
									<ButtonToolbar className="clear-both">
										<Button bsStyle="info" type="submit" className="pull-right">Save</Button>
									</ButtonToolbar>
								</div>
							}
						</form>
					</Modal.Body>
				</Modal>
				<ConfirmDeleteModal show={this.state.showConfirmModal} action={this.deleteData} close={this.handleConfirmClose}></ConfirmDeleteModal>
				{this.state.isLoading && 
					<div className="modal-backdrop in" >
						<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
					</div>
				}
			</div>
		);
	}
}

export default DashboardPage;