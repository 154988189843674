import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ConfirmDeleteModal extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
				<Modal show={this.props.show}>
					<Modal.Header>
						<Modal.Title>Confirm</Modal.Title>
					</Modal.Header>

					<Modal.Body>Are you sure you want to delete this?</Modal.Body>

					<Modal.Footer>
						<Button onClick={this.props.close}>No</Button>
						<Button bsStyle="primary" onClick={this.props.action}>Yes</Button>
					</Modal.Footer>
				</Modal>
    );
  }
}

export default ConfirmDeleteModal;

				