import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

class Navigationbar extends Component {
  constructor(props, context) {
    super(props, context);
  }

  handleLogout = (e) => {
    localStorage.removeItem('user');
    this.props.history.push('/');
  }

  redirectDashboard = (e) => {
    this.props.history.push('/dashboard');
  }

  redirectHospital = (e) => {
    this.props.history.push('/hospitals');
  }

  redirectToGeneMutation(e) {
    this.props.history.push('/genemutations');
  }

  redirectDssg = (e) => {
    this.props.history.push('/dssg');
  }

  redirectCity = (e) => {
    this.props.history.push('/cities');
  }

  redirectCountries = (e) => {
    this.props.history.push('/countries');
  }

  render() {
    const {pathname} = this.props.location;
    return (
      <Navbar collapseOnSelect className="white-box col-xs-4 col-sm-3 col-md-3 col-lg-2 p-0 menu-navbar">
          <Nav stacked>
            <NavItem eventKey={1} onClick={this.redirectDashboard} className={(pathname === '/dashboard' ? 'active' : '')}>
              Dashboard
            </NavItem>
            <NavItem eventKey={2} onClick={this.redirectHospital} className={(pathname === '/hospitals' ? 'active' : '')}>
              Hospitals
            </NavItem>
            <NavItem eventKey={3} onClick={() => this.redirectToGeneMutation()} className={(pathname === '/genemutations' ? 'active' : '')}>
              Gene Mutations
            </NavItem>
            <NavItem eventKey={4} onClick={this.redirectDssg} className={(pathname === '/dssg' ? 'active' : '')}>
              DSSG
            </NavItem>
            <NavItem eventKey={5} onClick={this.redirectCity} className={(pathname === '/cities' ? 'active' : '')}>
              Cities
            </NavItem>
            <NavItem eventKey={6} onClick={this.redirectCountries} className={(pathname === '/countries' ? 'active' : '')}>
              Countries
            </NavItem>
          </Nav>
          <Nav>
            <NavItem className="logout" onClick={this.handleLogout}>
              Logout
            </NavItem>
          </Nav>
      </Navbar>
    );
  }
}

export default Navigationbar;