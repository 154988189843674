import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, FormGroup, ControlLabel, FormControl, HelpBlock, ButtonToolbar, Button} from 'react-bootstrap';
import { BrowserRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import * as Constants from '../_constants/api';

class RegisterPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: '',
      password: '',
      confirmpassword: ''
    };
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password, confirmpassword } = this.state;

    // stop here if form is invalid
    if (!(email && password)) {
        return;
    }

    this.setState({ loading: true });
    
    axios.post(Constants.API_PATH + 'registration', {
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.confirmpassword
    })
    .then(result => {
      if(result.data.status === 1) {
        this.props.history.push('/dashboard');
      } else if(result.data.status === -1) {
        console.log(result.data.error);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }


  render() {
    const { email, password, confirmpassword, submitted, loading, error } = this.state;
    return (
      <Col lgOffset={4} lg={4} mdOffset={4} md={4} smOffset={3} sm={6} xsOffset={2} xs={8} className="sign-user align-middle pd-20 white-box">
        <form name="form" onSubmit={this.handleSubmit}>
          <FormGroup controlId="formEmail" >
            <ControlLabel>Email</ControlLabel>
            <FormControl type="text" value={email}  name="email" placeholder="Your Email" onChange={this.handleChange} />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="formPassword" >
            <ControlLabel>Password</ControlLabel>
            <FormControl type="password" value={password}  name="password" placeholder="Password" onChange={this.handleChange} />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="formConfirmPassword" >
            <ControlLabel> Confirm Password</ControlLabel>
            <FormControl type="password" value={confirmpassword}  name="confirmpassword" placeholder="Confirm Password" onChange={this.handleChange} />
            <FormControl.Feedback />
          </FormGroup>
          <ButtonToolbar>
            <Button bsStyle="info" type="submit">Sign up</Button>
            <Button>Reset</Button>
          </ButtonToolbar>
        </form>
      </Col>
    );
  }
}

export default RegisterPage;