import React, { Component } from 'react';
import HomePage from './HomePage/homepage';
import NavigationSideBar from './Dashboard/navigationbar';
import Dashboard from './Dashboard/dashboardpage';
import Hospitals from './HospitalsPage/hospitalspage';
import GeneMutations from './GeneMutationPage/genemutationpage';
import Cities from './CitiesPage/citiespage';
import Countries from './CountriesPage/countriespage';
import Dssg from './DssgPage/dssgpage';
import RegisterPage from './RegisterPage/registerpage';
import { PrivateRoute } from './_components/PrivateRoute';
import { LandingRoute } from './_components/LandingRoute';
import './App.css';
import './generic.css'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Row, Navbar } from 'react-bootstrap';
import LogoutNavComponent from './_components/LogoutNavItem';

class App extends Component {
  render() {
    return (
      <Router>
      <div className="App p-0">
        <Navbar className="blue-nav-bar">
          <Navbar.Header>
            <Navbar.Brand>
              <img src="https://app.oncoassist.com/admin/images/logo.png" />
            </Navbar.Brand>
            {/* <Navbar.Toggle /> */}
            {/* <LogoutNavComponent history= {this.props.history}></LogoutNavComponent> */}
          </Navbar.Header>
        </Navbar>
        <PrivateRoute exact path="/dashboard" component={NavigationSideBar}/>
        <PrivateRoute exact path="/hospitals" component={NavigationSideBar} />
        <PrivateRoute exact path="/genemutations" component={NavigationSideBar} />
        <PrivateRoute exact path="/dssg" component={NavigationSideBar} />
        <PrivateRoute exact path="/cities" component={NavigationSideBar} />
        <PrivateRoute exact path="/countries" component={NavigationSideBar} />
        <section className="col-xs-8 col-xs-offset-4 col-sm-9 col-sm-offset-3 col-md-9 col-md-offset-3 col-lg-10 col-lg-offset-2 p-0">
          <Row className="show-grid">
            <LandingRoute exact path="/" component={HomePage} />
            {/* <Route path="/sign-up" component={RegisterPage} /> */}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/genemutations" component={GeneMutations} />
            <PrivateRoute exact path="/hospitals" component={Hospitals} />
            <PrivateRoute exact path="/dssg" component={Dssg} />
            <PrivateRoute exact path="/cities" component={Cities} />
            <PrivateRoute exact path="/countries" component={Countries} />
          </Row>
        </section>
        <footer></footer>
      </div>
      </Router>
    );
  }
}

export default App;
