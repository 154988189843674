import React, { Component } from 'react';
import { Col, Table, ButtonToolbar, DropdownButton, MenuItem, Button, Modal, FormGroup, ControlLabel, FormControl, Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import ConfirmDeleteModal from '../_components/ConfirmDeleteModal';
import * as Constants from '../_constants/api';

class DssgPage extends Component {

	constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
			showAddModal: false,
			name: '',
			deleteThis: '',
			showConfirmModal: false
    };
  	this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handelDelete = this.handelDelete.bind(this);
		this.handleConfirmClose = this.handleConfirmClose.bind(this);
		this.deleteData = this.deleteData.bind(this);
		this.loadData = this.loadData.bind(this);
  }

  handleClose() {
    this.setState({ showAddModal: false });
  }

  handleShow() {
    this.setState({ showAddModal: true, name: '' });
  }

	handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

	handelDelete(DSSGId) {
		this.setState({
			showConfirmModal: true,
			deleteThis: DSSGId
		});
  }

	handleConfirmClose() {
    this.setState({ showConfirmModal: false });
  }

	deleteData() {
		const headers = {
			Authorization: localStorage.getItem('user')
		};
		const data = {
			dssgId: this.state.deleteThis
		};

		this.setState({ loading: true });
		axios.delete(Constants.API_PATH + 'dssg', {headers, data})
		.then(result => {
			if(result.data.status === 1) {
    		this.setState({ showConfirmModal: false, deleteThis: '' });
				this.loadData();
			} else if(result.data.status === -1) {
				console.log(result.data.error);
			}
		})
		.catch(error => {
			console.log(error);
		});
	}

	handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name } = this.state;

    // stop here if form is invalid
    if (!(name)) {
        return;
    }

    this.setState({ loading: true });
		axios.post(Constants.API_PATH + 'dssg', {
			name: this.state.name
		}, {headers: {
			Authorization: localStorage.getItem('user')
		}})
		.then(result => {
			if(result.data.status === 1) {
				this.handleClose();
				this.loadData();
			} else if(result.data.status === -1) {
				console.log(result.data.error);
			}
		})
		.catch(error => {
			console.log(error);
		});
	}

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		this.setState({ isLoading: true });
    try {
      const result = await axios.get(Constants.API_PATH + 'dssg', {
				headers: {
					Authorization: localStorage.getItem('user')
				}
			});
			if(result.data.status === 1) {
				this.setState({
					data : result.data.response,
					isLoading: false
				});
			} else {
				this.setState({
					error : result.data.error,
					isLoading: false
				});
			}
    } catch (error) {
      this.setState({
        error,
        isLoading: false
      });
    }
  }
	
	
  render() {
		const { name } = this.state;
    return (
      <div>
				<Col lgOffset={2} lg={8} mdOffset={2} md={8} smOffset={1} sm={10} xsOffset={1} xs={10} className="align-middle pd-20 white-box">
					<ButtonToolbar className="mrgb-10">
						<Button onClick={this.handleShow} className="primary-button">Add <Glyphicon glyph="plus" /> </Button>
					</ButtonToolbar>
					<Table striped condensed hover responsive bordered>
						<thead>
							<tr>
								<th>Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(thisData =>
								<tr key={thisData._id}>
									<td>{thisData.name}</td>
									<td>
										<DropdownButton title="Action" id="bg-vertical-dropdown-1" className="primary-button">
											<MenuItem eventKey="1" deleteid={thisData._id} onClick={() => this.handelDelete(thisData._id)}> <Glyphicon glyph="remove" />Remove</MenuItem>
										</DropdownButton>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
				<Modal show={this.state.showAddModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add DSSG</Modal.Title>
          </Modal.Header>
          <Modal.Body>
						<form name="form" onSubmit={this.handleSubmit}>
							<FormGroup controlId="formEmail" >
								<ControlLabel>Name</ControlLabel>
								<FormControl type="text" value={name}  name="name" placeholder="DSSG name" onChange={this.handleChange} />
								<FormControl.Feedback />
							</FormGroup>
							<ButtonToolbar>
								<Button bsStyle="info" type="submit">Save</Button>
							</ButtonToolbar>
					</form>
					</Modal.Body>
				</Modal>
				<ConfirmDeleteModal show={this.state.showConfirmModal} action={this.deleteData} close={this.handleConfirmClose}></ConfirmDeleteModal>

				{this.state.isLoading && 
					<div className="modal-backdrop in" >
						<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
					</div>
				}
      </div>
    );
  }
}

export default DssgPage;